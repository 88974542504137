import React from 'react';
import {Helmet} from '../components/Helmet';
import PageLayout from '../components/PageLayout';
import {graphql} from 'gatsby';
import {PageContext} from 'gatsby/internal';
import {StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby-plugin-react-i18next';
import {pushEvent} from '../services/gtmManager';

interface Context extends PageContext {
  title: string;
  slug: string;
  description: string;
  sameLetterDreams: {slug: string; title: string}[];
}

const SapnoReiksme = ({pageContext}: Context) => {
  return (
    <>
      <Helmet
        title={`${pageContext.title} sapne | Ką reiškia sapnuoti ${pageContext.title}?`}
        description={`Sapno reikšmė ${pageContext.title}. Ką reiškia ${pageContext.title} sapne? Laimos sapnininko interpretacija: ${pageContext.title} sapne.`}
      />
      <PageLayout hideFooter logoLink='/lt' className='bg-white text-black text-lg' lowContent hideAuthentication>
        <div className='container py-10 flex gap-4 items-center justify-center'>
          <section className='lg:w-1/2'>
            <h1 className='text-4xl mb-4'>
              <div className='mb-2'>
                <strong>{pageContext.title} sapne</strong>
              </div>
              <div>Ką reiškia sapnuoti šį simbolį?</div>
            </h1>
            <StaticImage
              className='mb-6 rounded-2xl'
              imgClassName='rounded-2xl'
              width={752}
              quality={100}
              src='../images/sapno-reiksmes-paaiskinimas.png'
              alt='Moteris lovoje prabudusi po sapno'
            />
            <p
              className='whitespace-pre-wrap'
              dangerouslySetInnerHTML={{
                __html: pageContext.description,
              }}
            />
          </section>
        </div>
        <section className='container mb-32 lg:w-1/2'>
          <p className='mb-2 text-center'>Kitų sapnų reikšmės:</p>
          <div className='flex gap-2 flex-wrap justify-center'>
          {pageContext.sameLetterDreams.map((sameLetterDream: Context['sameLetterDreams'][0]) =>
            sameLetterDream.slug === pageContext.slug ? (
              <div key={sameLetterDream.slug} className='text-base font-bold'>{sameLetterDream.title}</div>
            ) : (
              <Link className='text-base' key={sameLetterDream.slug} to={`/sapno-reiksme/${sameLetterDream.slug}`}>
                {sameLetterDream.title}
              </Link>
            )
          )}
          </div>
        </section>
        <section className='fixed bottom-0 left-0 w-full bg-black bg-opacity-90 text-white p-2 text-center'>
          <div className='text-xl mb-1'>Kankina sapnai?</div>
          <Link
            className='btn btn-secondary text-white text-xl mb-2'
            to='/lt'
            onClick={() => pushEvent('web', {action: 'dreamFortuneClick'})}
          >
            Sužinokite kas jūsų laukia!
          </Link>
        </section>
      </PageLayout>
    </>
  );
};

export default SapnoReiksme;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["index"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
